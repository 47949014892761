.team-container {
  padding: 8rem 0;
}

.team-title {
  display: grid;
  place-items: center;
}
.team-title h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 0.5rem;
  font-family: "Trump";
}
.team-title > p {
  text-align: center;
  width: 60rem;
  color: rgb(167, 165, 165);
  margin: 0 auto;
}

.team-center {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.8rem;
  column-gap: 2rem;
}

.show-team-reels {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.show-team-reels-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  background-color: transparent;
  z-index: 99;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #d07c56;
}

.show-team-reels-img {
  width: 100%;
  height: 440px;
  position: relative;
  transition: all 2s cubic-bezier(0.25, 1, 0.25, 1);
}

.show-team-reels-img:hover:before {
  opacity: 0.8;
  background: transparent;
  transition: all 2s cubic-bezier(0.25, 1, 0.25, 1);
}

.show-team-reels-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: opacity(0.7);
  transition: all 0.3s linear;
}

.show-team-reels > .team-title {
  /* position: absolute; */
  /* width: 60%;
  height: 30%; */
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  font-size: 25px;
  /* background: rgba(0, 0, 0, 0.4); */
  color: #ffffff;
  padding: 1rem 0.5rem;
  font-weight: 500;
  transition: all 0.3s linear;
  font-family: "Trump";
  text-transform: uppercase;
}

.show-team-reels:hover > img {
  filter: opacity(1);
}

.show-team-reels:hover .title {
  color: #ffffff;
}

@media screen and (max-width: 450px) {
  .team-title h1 {
    font-size: 40px;
  }

  .team-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .team-center {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 70%;
  }

  .show-team-reels {
    width: 100%;
  }

  .show-team-reels > .title {
    font-size: 26px;
  }

  .team-title > p {
    width: 100%;
  }
  .show-team-reels-img {
    height: 320px;
  }

  .show-team-reels-img::before {
    background: transparent;
  }
}

@media screen and (min-width: 451px) and (max-width: 747px) {
  .team-title h1 {
    font-size: 40px;
  }

  .team-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .team-center {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 70%;
  }

  .show-team-reels {
    width: 100%;
  }

  .show-team-reels > .title {
    font-size: 26px;
  }

  .team-title > p {
    width: 100%;
  }
  .show-team-reels-img {
    height: 320px;
  }
}

@media screen and (min-width: 748px) and (max-width: 1024px) {
  .show-team-reels-img {
    height: 380px;
  }
  .team-center {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 1rem;
  }

  .show-team-reels {
    width: 100%;
  }

  .team-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .team-reels > img {
    object-fit: contain;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .team-center {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 4rem;
  }

  .show-team-reels {
    width: 100%;
  }

  .show-team-reels-img {
    height: 320px;
  }

  .team-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .team-reels > img {
    object-fit: contain;
  }
  .show-team-reels-img {
    height: 380px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .team-center {
    width: 80%;
    grid-template-columns: 280px 280px 280px 280px;
  }

  /* .show-team-reels{
    width: 100%;
  }

.show-team-reels-img{
  height: 280px;
} */
}
