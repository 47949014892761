.sidebar {
  position: fixed;
  width: 80vw;
  top: 0;
  left: -100%;
  height: 100vh;
  overflow: hidden;
  background: #141414;
  z-index: 9999;
  transition: all 0.3s linear;
}

.sidebar-center {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  place-items: center;
}

.sidebar-links {
  display: grid;
  row-gap: 0.75rem;
}

.sidebar-links > li {
  font-size: 32px;
}

.sidebar-links > li > a {
  font-family: 'Futura', sans-serif;
  font-weight: 100;
}

.active {
  left: 0%;
}

@media screen and (min-width: 540px) {
  .sidebar {
    display: none;
  }
}
