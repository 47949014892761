.media-coverage {
  padding: 100px 130px;
  width: 100%;
}
.media-heading {
  font-size: 2rem;
  width: 100%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.media-image {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.media-image-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.media-card-header {
  display: flex;
  gap: 4px;
  align-items: baseline;
}
.partner {
  font-size: 1rem;
  text-decoration: underline;
}
.visitors {
  font-size: small;
}

.media-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.media-Links {
  display: grid;
  gap: 10px;
  column-gap: 200px;
  grid-template-columns: 1fr 1fr 1fr;
}
.media-link {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  /* border: 1px solid rgb(0, 0, 0, 0.4); */
  border-radius: 5px;
  cursor: pointer;
}
.media-link:hover {
  background-color: rgb(0, 0, 0, 0.1);
  color: #ee9617;
}

.description {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
}
