nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: transparent;
  display: grid;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(4px);
}

.nav-center {
  width: 90vw;
  height: 80px;
  /* max-width: 1492px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 100px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 30%;
}

.nav-link {
  transition: all 0.3s ease-in-out;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 11.7556px;
}

/* .nav-link:hover {
  color: var(--accent);
} */

.toggle {
  color: #ffffff;
  font-size: 32px;
  display: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

.toggle:hover {
  color: var(--accent);
}

.flip-animate-1 {
  perspective: 1000px;
  /* font-family: 'TrumpBold'; */
  letter-spacing: 1.2px;
}

.flip-animate-1 span {
  position: relative;
  display: inline-block;
  padding: 0;
  transition: transform 0.3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}
.flip-animate-1 span:before {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  content: attr(data-hover);
  transition: color 0.3s;
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
  /* text-align: center; */
}
.flip-animate-1:hover span,
.flip-animate-1:focus span {
  transform: rotateX(90deg) translateY(-22px);
}
.flip-animate-1:hover span:before,
.flip-animate-1:focus span:before {
  color: #ee7739;
  opacity: 1;
}

@media screen and (min-width: 450px) and (max-width: 1300px) {
  .nav-links {
    flex: 0 0 45%;
  }
}

@media screen and (min-width: 300px) and (max-width: 450px) {
  /* nav {
    width: 100;
  } */
  .nav-links {
    display: none;
  }

  .toggle {
    display: block;
  }

  .logo {
    width: 130px;
  }
}
