#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* #myVideo2 {
  margin: auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  width: auto;
  height: 502px;
  border-radius: 50%;
} */

.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../Assets/home.jpg) no-repeat;
  background-size: cover; */
}

/* .home-center {
  min-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  top: 0;
  left: 0;
  overflow: hidden;
 }  */

.home-center {
  overflow: hidden;
  -webkit-overflow: hidden;
}

/* .hero-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: var(--secondary);
  font-size: 68px;
  line-height: 1.1;
  font-weight: 400;
} */

.hero-title {
  /* position: relative; */
  width: 100%;
  /* height: 100vh; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-title > h1 {
  color: var(--secondary);
  font-size: 68px;
  line-height: 1.1;
  font-weight: 400;
  z-index: 999;
  text-align: center;
}

.hero-title > h3 {
  margin-top: 3rem;
  color: var(--secondary);
  font-size: 28px;
  line-height: 1.1;
  font-weight: 300;
  z-index: 999;
  text-align: center;
}

.gradient {
  background-image: radial-gradient(
    circle at 36% 48%,
    #000000,
    rgba(11, 39, 65, 0.32) 50%,
    rgba(0, 0, 0, 0)
  );
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

@media screen and (min-width: 540px) and (max-width: 1200px) {
  .hero-title {
    position: none !important;
    font-size: 55px;
    top: 50%;
    left: 50%;
  }
  /* 
  #myVideo {
    display: none;
  } */
}

@media screen and (max-width: 450px) {
  /* #myVideo {
    display: none;
  } */

  #myVideo2 {
    display: none !important;
  }

  .hero-title > h1 {
    font-size: 38px;
  }

  .hero-title > h3 {
    font-size: 18px;
  }

  .home {
    width: 100%;
    height: 100vh;
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../Assets/home.jpg) no-repeat;
    background-size: cover; */
  }
}

/* @media screen and (min-width: 300px) and (max-width: 370px) {
  .hero-title {
    font-size: 30px;
    width: 70%;
    left: 10%;
    top: 30%;
    overflow: hidden;
  }
} */

.tags {
  background-color: transparent;
  color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: "-50%";
}
