.projects-center {
  padding-top: 6rem;
  padding-bottom: 3rem;
}

.projects-center h1 {
  text-transform: uppercase;
  font-size: 40px;
  font-family: 'TrumpBold';
}

.back-btn {
  margin-bottom: 2rem;
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  font-size: 20px;
}

.projects-detail-container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 53% auto;
  column-gap: 2.5rem;
  overflow-y: hidden;
}

/* .projects-detail-container > iframe {
  border-radius: 10px;
} */

/* .project-content {
  overflow-y: scroll;
} */

.project-content p {
  line-height: 1.4;
  margin-bottom: 1rem;
  font-size: 22px;
}

.project-content h4 {
  margin-bottom: 1rem;
  font-size: 17px;
  font-family: 'Futura';
  font-weight: 300;
}

.project-content h4 > span:first-child {
  color: rgb(167, 165, 165);
  margin-right: 0.2rem;
  font-size: 17px;
  transform: translateY(4%);
}

.location {
  display: flex;
  margin-top: 1rem;
}

.tags {
  display: flex;
  /* align-items: center; */
}

.pills {
  margin-left: 5px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 0.75rem;
}

.tag {
  background: var(--accent) !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .projects-detail-container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .projects-detail-container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .projects-center h1 {
    font-size: 25px;
  }

  .project-content p {
    line-height: 1.5;
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
