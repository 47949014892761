footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  width: 100%;
  z-index: 99;
}

.footer-center {
  display: flex;
  justify-content: space-between;
}

.footer-name > h4 {
  font-size: 13.75px;
}

.footer-links {
  flex: 0 0 12%;
  display: flex;
  justify-content: space-between;
}

/* .footer-links > h5,
h4 {
  font-weight: 300;
  font-size: 18px;
} */

.footer-links > h5 > a {
  font-weight: 300;
  font-size: 16px;
}

.footer-links h5:first-child {
  padding-right: 7px;
  /* border-right: 1px solid #ffffff; */
}

.footer-links h5:last-child {
  padding-left: 7px;
}

.footer-social-links {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
}

.footer-social-links > a {
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transition: all 0.3s linear;
}

.footer-icon {
  font-size: 12px;
  color: #ffffff;
}

@media screen and (max-width: 766px) {
  footer {
    position: relative;
    left: 0;
  }
  .footer-center {
    display: grid;
    justify-content: center;
    padding: 0 0 3rem 0;
    row-gap: 0.5rem;
  }
  .footer-links h5:first-child {
    padding-right: 4px;
    /* border-right: 1px solid #ffffff; */
  }

  .footer-links h5:last-child {
    padding-left: 4px;
  }
  .footer-links {
    flex: 0 0 0;
    display: flex;
    justify-content: center;
    order: 3;
  }
}
