.reels {
  padding: 8rem 0;
}

.reels-title {
  display: grid;
  place-items: center;
}
.reels-title h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 0.5rem;
  font-family: 'Trump';
}
.reels-title > p {
  text-align: center;
  width: 60rem;
  color: rgb(167, 165, 165);
  margin: 0 auto;
}

.reels-center {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.8rem;
  column-gap: 1rem;
}

.show-reels {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.show-reels-img{
  width: 100%;
  height: 250px;
}

.show-reels-img img {
  width: 100%;
  height: 100%;
  object-fit: initial;
  filter: opacity(0.7);
  transition: all 0.3s linear;
}


.show-reels > .title {
  /* position: absolute; */
  /* width: 60%;
  height: 30%; */
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  font-size: 25px;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  padding: 1rem 0.5rem;
  font-weight: 500;
  transition: all 0.3s linear;
  font-family: 'Trump';
  text-transform: uppercase;
}

.show-reels:hover > img {
  filter: opacity(1);
}

.show-reels:hover .title {
  background: rgb(0, 0, 0, 0.7);
  color: #ffffff;
}

.modal-show-reel {
  position: fixed;
  top: 0%;
  left: -250%;
  opacity: 0;
  width: 100%;
  min-height: 100vh;
  /* transform: translate(-50%, -50%); */
  background: rgba(9, 10, 15, 0.8);
  display: grid;
  padding: 1rem;
  border-radius: 10px;
  z-index: 999;
  transition: all 0.3s linear;
}

.modal-content {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.modal-content > button {
  padding: 0.7rem 1rem;
  background: #24201d;
  border: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
}

.active {
  left: 0%;
  opacity: 1;
}

.frame {
  width: 50vw;
  height: 50vh;
}

@media screen and (max-width: 450px) {
  .reels-title h1 {
    font-size: 40px;
  }

  .reels-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .reels-center {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .frame {
    width: 90vw;
    height: 300px;
  }

  .show-reels > .title {
    font-size: 26px;
  }

  .modal-show-reel {
    top: 0%;
  }

  .active {
    left: 0%;
  }
  .reels-title > p {
    width: 100%;
  }
}

@media screen and (min-width: 740px) and (max-width: 1100px) {
  .reels-center {
    grid-template-columns: 1fr 1fr;
    gap: 2rem 1rem;
  }

  .reels-title > p {
    width: 100%;
    font-size: 18.72px;
  }

  .show-reels > img {
    object-fit: contain;
  }
}
