.career-center {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.career-center h1 {
  text-align: center;
  font-family: 'Trump';
  margin-bottom: 5rem;
  font-size: 50px;
}

.career-container {
  width: 70vw;
  margin: 0 auto;
}

.career {
  display: grid;
  margin-bottom: 1.5rem;
}

.career-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
}

.career-title h2 {
  font-size: 28px;
  display: flex;
  align-items: center;
  font-family: 'Trump';
}

.career-btn {
  width: 90px;
  padding: 0.75rem 0;
  font-size: 12px;
  margin-right: 20px;
  cursor: pointer;
  background-color: #ee9617;
  background-image: linear-gradient(to left, #ee9617, #ee7739, #ee9617);
  border: none;
  outline: none;
  color: #ffffff;
  text-transform: uppercase;
}

.career-content {
  display: block;
  margin-top: 1.5rem;
  transition: all 0.3s linear;
  padding: 0 3rem;
}

.career-content h3 {
  text-align: left;
  color: rgb(167, 165, 165);
  font-size: 16px;
}

.down-btn {
  font-size: 22px;
  cursor: pointer;
  margin-right: 10px;
}

.active {
  display: block;
}

.roles {
  margin-top: 1rem;
}

.roles > li {
  list-style-type: none;
  font-size: 24px;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .career-container {
    width: 80vw;
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .career-center h1 {
    font-size: 40px;
  }

  .career-container {
    width: 90vw;
  }

  .career-title {
    display: grid;
  }
  .career-title h2 {
    align-items: baseline;
  }

  .career-title h3 {
    font-size: 16px;
    text-align: left;
  }

  .career-title > div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
  }

  .career-btn {
    width: 130px;
    font-size: 16px;
  }

  .career-content {
    padding: 0 0 0 1rem;
  }

  .roles > li {
    font-size: 22px;
  }
}
