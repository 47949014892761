.work {
  height: 100vh;
  padding: 5rem 0;
}

.work-center {
  display: grid;
  grid-template-columns: 350px auto;
}

.filter-container {
  height: 100vh;
  /* background: turquoise; */
  padding: 4rem 2rem;
  position: fixed;
  left: 4%;
  /* display: flex; */
  /* border-right: 0.5px solid var(--accent); */
}

.filter-container h4 {
  font-size: 14px;
  color: rgb(167, 165, 165);
  margin-bottom: 20px;
  padding: 15px 0;
  letter-spacing: 0.18px;
}

.filters {
  margin-top: 1rem;
}

.filter {
  outline: none;
  background: transparent;
  border: transparent;
  text-align: left;
  font-size: 14px;
  color: rgb(167, 165, 165);
  margin-bottom: 0.6rem;
  padding: 0.3rem 0.5rem;
  display: block;
  transition: all 0.1s ease-in;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.filter:hover {
  border: 1px solid var(--primary);
  color: #ffffff;
}

.filter-active {
  border: 1px solid rgb(167, 165, 165);
  color: #ffffff;
}

.projects {
  width: 100%;
  padding: 4rem 0 15rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 13rem;
  column-gap: 0rem;
}

.project {
  width: 450px;
  height: 300px;
  transform: scale(0.9);
  transition: all 0.3s linear;
}

.work-image > img {
  transform: scale(1);
  transition: all 0.3s linear;
}

.work-image > img:hover {
  transform: scale(1.1);
  filter: grayscale(0.5);
}

.project img {
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
}

.project h2 {
  font-size: 28px;
  /* font-weight: 300; */
  text-transform: uppercase;
  font-family: 'TrumpBold';
}

.flip-animate {
  perspective: 1000px;
  font-family: 'TrumpBold';
  letter-spacing: 1.2px;
}

.flip-animate span {
  position: relative;
  display: inline-block;
  padding: 0;
  transition: transform 0.3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}
.flip-animate span:before {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  content: attr(data-hover);
  transition: color 0.3s;
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
  /* text-align: center; */
}
.project:hover .flip-animate span {
  transform: rotateX(90deg) translateY(-22px);
}
.project:hover .flip-animate span::before {
  color: #ee7739;
  opacity: 1;
}

.mobile-filter-container {
  position: relative;
  padding: 0.75rem 0;
  text-align: center;
  display: none;
  overflow: hidden;
}

.filter-btn {
  background: none;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  cursor: ponter;
  position: relative;
}

.filter-down {
  position: absolute;
  top: 15%;
  right: -40%;
  /* margin-left: 10%; */
}

.mobile-filters {
  margin-top: 1.5rem;
  justify-content: center;
  row-gap: 0.5rem;
  width: 100%;
  display: none;
}

.mobile-filter {
  display: block;
  width: 100%;
  text-align: center;
  outline: none;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  padding: 0.3rem;
  transition: all 0.3s linear;
  cursor: pointer;
  text-transform: uppercase;
}

.mobile-filter-active {
  border: 1px solid rgb(167, 165, 165);
  color: #ffffff;
}

@media screen and (min-width: 766px) and (max-width: 868px) {
  .work {
    height: auto;
    padding: 5rem 0 5rem 0;
  }
  .work-center {
    display: grid;
    width: 70vw;
    margin: 0 auto;
    grid-template-columns: 1fr;
  }

  .filter-container {
    display: none;
  }

  .projects {
    grid-template-columns: 1fr;
    padding-top: 2rem;
    row-gap: 18rem;
  }

  .project {
    width: auto;
  }

  .project img {
    object-fit: cover;
    height: 400px;
  }

  .project h2 {
    font-size: 22px;
    text-align: center;
  }

  .mobile-filter-container {
    display: block;
  }

  .active-filter {
    display: grid;
  }
}

@media screen and (min-width: 868px) and (max-width: 1100px) {
  .work-center {
    display: grid;
    grid-template-columns: 350px auto;
  }

  .projects {
    column-gap: 1rem;
    row-gap: 6rem;
  }

  .project {
    width: 100%;
  }

  .project img {
    height: auto;
  }

  .project h2 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .projects {
    column-gap: 2rem;
    row-gap: 8rem;
  }

  .project {
    width: auto;
  }
  .project h2 {
    font-size: 22px;
  }

  .project img {
    height: auto;
  }
}

@media screen and (min-width: 300px) and (max-width: 766px) {
  .work {
    height: auto;
    padding: 5rem 0 0 0;
  }
  .work-center {
    display: grid;
    grid-template-columns: 1fr;
  }

  .filter-container {
    display: none;
  }

  .projects {
    grid-template-columns: 1fr;
    padding-top: 2rem;
  }

  .project {
    width: auto;
  }

  .project img {
    object-fit: contain;
  }

  .project h2 {
    font-size: 22px;
  }

  .mobile-filter-container {
    display: block;
  }

  .active-filter {
    display: grid;
  }
}
