.studio-header {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  /* justify-content: center;
  align-items: center; */
}

.studio-header-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70vw;
}

.studio-header-center h1 {
  font-family: "Trump";
  font-size: 40px;
}
/* .studio-header > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.studio-header h3 {
  margin-bottom: 2rem;
}

.studio-header p {
  line-height: 1.6;
  font-size: 24px;
  margin-top: 1rem;
  font-family: "Futura";
}

.studio-header p > span {
  font-weight: 600;
  font-size: 26px;
  font-family: "FuturaSemi";
  color: var(--accent);
}

.studio-center {
  padding: 0rem 0 5rem 0;
  width: 70vw;
}

.studio-about {
  /* margin-bottom: 2rem; */
}

.studio-about h1 {
  color: rgb(167, 165, 165);
  font-size: 16px;
  margin-bottom: 2.5rem;
}

.studio-about p {
  line-height: 1.6;
  font-size: 24px;
  margin-top: 1rem;
  font-family: "Futura";
}

.studio-about p > span {
  font-weight: 300;
  color: var(--accent);
  font-size: 26px;
}

.studio-snapshots {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  row-gap: 3rem;
}

.studio-title {
  color: rgb(167, 165, 165);
  font-size: 16px;
}

.snapshot {
  text-align: center;
  width: 200px;
  display: grid;
}

.snapshot h1 {
  margin-top: 0.5rem;
  font-size: 35px;
}

/* .first {
  margin-top: 2rem !important;
} */

.snapshot h5 {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
}

.studio-snapshot-wrapper {
  /* background: #000000; */
  padding: 5rem 0;
}

.studio-snapshot-container {
  width: 70vw;
  margin: 0 auto;
}

.services-wrapper {
  margin: 5rem 0 3rem 0;
}

.services-container {
  width: 70vw;
  margin: 0 auto;
}

.services-container h1 {
  margin-bottom: 3rem;
  color: rgb(167, 165, 165);
  font-size: 16px;
}

.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: left;
  row-gap: 3rem;
}

.service {
  display: flex;
  row-gap: 1.5rem;

  align-items: center;
  flex-direction: column;
  /* border-bottom: 0.5px solid var(--accent); */
  padding-bottom: 3rem;
}

.service-title {
  display: flex;
  flex-direction: column;

  align-items: center;
}

/* 
.service-title > img {
  width: 100px;
  margin-top: 1rem;
} */

.service-icon {
  width: 80px;
  margin-top: 1rem;
}

.service-content {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin-top: 1rem;
  align-self: center;
}

.service h2 {
  margin-bottom: 0.756rem;
  text-align: center;
}

.service h3 {
  font-weight: 400;
  text-align: center;
}

.service-content > h3 {
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px solid var(--accent);
  font-size: 23px;
}

.service-content > h3:last-child {
  border: none;
}

.clients-wrapper {
  /* background: #000000; */
  padding: 5rem 0;
}

.clients-container {
  width: 70vw;
  margin: 0 auto;
}

.clients-container h1 {
  color: rgb(167, 165, 165);
  font-size: 16px;
  margin-bottom: 3rem;
}

.clients-carousel {
  background: #242324;
  padding: 2rem 2rem 2rem 2rem;
}

.clients {
  /* margin-top: 3rem; */
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  row-gap: 2rem;
  column-gap: 2rem;
}

.clients > img {
  width: 80px;
  /* height: 50px; */
}

.teams-wrapper {
  margin: 5rem 0;
}

.teams-container {
  width: 70vw;
  margin: 0 auto;
}

.teams-container h1 {
  color: rgb(167, 165, 165);
  font-size: 16px;
}

.teams {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem;
  /* grid-template-columns: repeat(3, 1fr);
  row-gap: 1.2rem; */
}

.team {
  width: 280px;
  /* display: grid;
  row-gap: 0.5rem; */
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.1s linear;
}

.team:hover {
  transform: scale(1);
}

.team img {
  width: 100%;
  filter: grayscale(100%);
  transition: all 0.3s linear;
  margin-bottom: 2.5rem;
}

.team:hover > img {
  filter: grayscale(0);
}

.team h2,
h3 {
  text-align: center;
}

.team h3 {
  margin-top: 1rem;
  color: rgb(167, 165, 165);
}

.employees {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.2rem;
}

.team > img {
  width: 100%;
}

.mobile-snapshot {
  display: none;
}

.groups-container {
  margin-bottom: 5rem;
}

.groups-center {
  width: 70vw;
  margin: 0 auto;
  display: grid;
  place-items: center;
}

.groups {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.group-img {
  width: 120px;
}
/* .group-first {
  width: 100px;
height: 100px;
  margin-right: 1.2rem;
}

.group-second {
  width: 90px;
  height: 50px;
}

.group-third {
  width: 130px;
  height: 50px;
  margin-left: 1.5rem;
}

.group-fourth {
  width: 130px;
  height: auto;
}



.group-six{
  width: 160px;
  height: 80px;
  margin-left: 1rem;
} */

/* .groups img:last-child {
  width: 100px;
} */

.group-five {
  margin-left: -2rem;
}

.groups-center h1 {
  text-align: center;
  margin-bottom: 0rem;
}

@media screen and (min-width: 540px) and (max-width: 866px) {
  .studio-header-center h1 {
    font-size: 40px;
  }
  .studio-header-center h3 {
    font-size: 16px;
  }

  .studio-header-center h1,
  h3 {
    text-align: center;
    margin-top: 0.5rem;
  }

  .studio-about > p {
    font-size: 22px;
  }

  .studio-about p > span {
    font-size: 24px;
  }

  .services {
    grid-template-columns: repeat(2, 1fr);
  }

  .service-title {
    justify-content: center;
  }

  .service-title h2 {
    font-size: 18px;
  }

  .service-title > img {
    width: 80px;
  }

  .service-content {
    column-gap: 2rem;
    justify-content: center;
  }

  .clients {
    grid-template-columns: repeat(5, 1fr);
  }

  .teams {
    grid-template-columns: repeat(2, auto);
    column-gap: 2rem;
  }

  .mobile-snapshot {
    display: block;
  }

  .desktop-snapshot {
    display: none;
  }

  .studio-snapshots {
    grid-template-columns: repeat(3, 1fr);
  }

  .studio-header {
    background-size: 100% 100%;
    height: 65vh;
  }

  .studio-header-center h1 {
    text-align: center;
  }

  .service-content h3 {
    text-align: center;
  }
  .team > h3 {
    text-align: center;
  }

  .team {
    width: 100%;
  }
}

@media screen and (min-width: 866px) and (max-width: 1200px) {
  .studio > h1 {
    font-size: 24px;
  }

  .studio-header-center h1,
  h3 {
    text-align: center;
    margin-top: 0.5rem;
  }

  .studio-about > p {
    font-size: 22px;
  }

  .studio-about p > span {
    font-size: 24px;
  }

  .service-title h2 {
    font-size: 18px;
  }

  .service-title > img {
    width: 80px;
  }

  .service-content {
    column-gap: 2rem;
  }

  .clients {
    grid-template-columns: repeat(5, 1fr);
  }

  .teams {
    grid-template-columns: repeat(2, auto);
    column-gap: 2rem;
  }

  .mobile-snapshot {
    display: block;
  }

  .desktop-snapshot {
    display: none;
  }

  .studio-header {
    background-size: 100% 100%;
  }

  .studio-header-center h1 {
    text-align: center;
  }

  .service-content h3 {
    text-align: center;
  }
  .team > h3 {
    text-align: center;
  }

  .team {
    width: 300px;
  }
}

@media screen and (min-width: 300px) and (max-width: 540px) {
  .studio > h1 {
    font-size: 28px;
  }

  .studio-center {
    width: 90vw;
  }

  .studio-header {
    background-size: 100% 100%;
    background-position: center center;
  }

  .studio-header-center h1,
  h3 {
    text-align: center;
    margin-top: 0.5rem;
  }

  .studio-about > p {
    font-size: 22px;
  }

  .studio-about p > span {
    font-size: 24px;
  }

  .studio-snapshot-container {
    width: 90vw;
  }

  .studio-snapshots {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .snapshot {
    width: 100%;
  }

  .services {
    grid-template-columns: 1fr;
  }

  .service {
    grid-template-columns: 1fr;
  }

  .service-title {
    justify-content: center;
  }

  .service-content {
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
  }

  .service-title {
    display: grid;
    row-gap: 1rem;
    place-items: center;
  }

  .service-title > img {
    margin-right: 0;
  }

  .clients {
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }

  .teams {
    grid-template-columns: 1fr;
  }

  .team {
    width: 100%;
  }
  .team > h3 {
    text-align: center;
  }

  .studio-header {
    background-size: cover;
  }

  .mobile-snapshot {
    display: block;
  }

  .desktop-snapshot {
    display: none;
  }

  .service-content h3 {
    text-align: center;
  }

  .groups {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    place-items: center;
    /* row-gap: 1rem; */
    margin-top: 1rem;
  }

  .groups img {
    width: 100%;
    margin-bottom: -3rem;
  }

  .group-five {
    margin-left: 0rem;
  }

  /* .group-third{
    margin-left: 0;
  }

  .group-five{
    height: 80px;
  } */

  /* .groups img:last-child {
    width: 100px;
  } */

  .groups-center {
    width: 90vw;
    row-gap: 1.5rem;
  }

  .groups-container {
    margin-bottom: 2rem;
  }

  .team-link {
    float: none !important;
    margin-top: 1rem;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    justify-content: center;
  }
}
