.copyrights {
  padding: 8rem 0 3rem 0;
}

.copyright-center {
  width: 70vw;
  display: grid;
  row-gap: 2rem;
}

.copyright {
  display: grid;
  row-gap: 1rem;
}

.copyright p {
  line-height: 1.5;
}
